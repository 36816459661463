@import '../../../../../libs/components/src/v1/Shared';

$plane: 'div:not(:first-child)';

.form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  @extend %drawer-sections;
}

.actions {
  @extend %drawer-actions;
}

.costs {
  padding: 20px 0;
}

.action-button-container {
  @extend %drawer-action-container;
}

.title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @extend %drawer-title;

  @include media-m {
    :global(#{$plane}) {
      display: none;
    }
  }
  @include media-m-landscape {
    :global(#{#{$plane}}) {
      display: none;
    }
  }
}

.link {
  @extend %link;
}
