//TODO: Make readable
@import '../../../../../libs/components/src/v1/Shared';

.column {
  flex-direction: column;
}

.summary {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  @extend %drawer-sections;

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;

    transition: color $fullscreen-transition-length;

    margin-bottom: 33px!important;
    border: none;

    background-color: transparent;
    cursor: pointer;

    color: $dark-grey !important;
    text-decoration: underline;
    font-size: $font-size-s;

    svg {
      transition: fill $fullscreen-transition-length;

      margin-right: 10px;
      width: 6px;
      height: auto;

      fill: currentColor;
    }

    & + * {
      margin-top: 30px;
    }

    &:hover {
      color: $black;
    }
  }
  &__title {
    @extend %h1;
    text-align: start;
  }
  &__button {
    //@extend %h6;
    text-align: start;
  }
}

.link {
  display: none;
  margin: 20px 0 30px;

  text-align: center;

  @include media-l {
    display: block;
  }
}

.actions {
  @extend %drawer-actions;
  padding-top: 20px;
}

.costs {
  padding: 20px 0;
}

.action-button-container {
  @extend %drawer-action-container;
}
