@import '../../../../../libs/components/src/v1/Shared';

.sections {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  @extend %drawer-sections;
}

.actions {
  @extend %drawer-actions;
}

.costs {
  padding: 20px 0;
}

.action-button-container {
  @extend %drawer-action-container;
}

.link {
  @extend %link;
}

.dropdown-subtitle {
  font-size: $font-factor-0;
  color: $dark-grey;
  text-transform: uppercase;
  padding-bottom: 5px;
}
