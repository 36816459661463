
.fatal-error {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.center {
  max-width: 50%;
  text-align: center;
}

.header {
  font-size: xx-large;
  font-family: 'Prompt', sans-serif
}
.subText {
  color: grey;
  font-family: 'Prompt', sans-serif
}
.redirectText {
  padding-top: 15px;
  color: lightgray;
  font-family: 'Prompt', sans-serif
}